import React, { useEffect, useState } from 'react';
import '.././App.css';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { setDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


function Register() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    useEffect(() => {
        if (localStorage.getItem('user_info')) {
        }
    })
    const [name, setName] = useState("")
    const [role, setrole] = useState("Admin")
    const [mobile, setMobile] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const  signup = async(e) => {
        e.preventDefault();
        try {
            await createUserWithEmailAndPassword(auth, username, password);
            const user = auth.currentUser;
            if(user){
                await setDoc(doc(db, "Users", user.uid), {
                    email : user.email,
                    role:role,
                    mobile:mobile,
                });
            }
            Toast.fire({
                icon:"success",
                title: 'User Registered Successfully!!'
            })
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: 'Please Enter Username Or Password'
            })
        }
    }

    return (
        <>
            <body class="hold-transition register-page">
                <div className="register-box">
                    <div className="register-logo">
                        <a href="#0"><b>Matoshri   </b>Bar</a>
                    </div>
                    <div className="card">
                        <div className="card-body register-card-body">
                            <p className="login-box-msg">Register a new membership</p>
                            <form onSubmit={signup}>
                                <div className="input-group mb-3">
                                    <select className="form-control" value={role} onChange={(e) => setrole(e.target.value)}>
                                        <option value="Admin">Admin</option>
                                    </select>
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-table" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full name" required/>
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-user" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder="Mobile No" required/>
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-phone" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)}  required placeholder="Email / Username " />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} required placeholder=" password" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* /.col */}
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block">Register</button>
                                    </div>
                                    {/* /.col */}
                                </div><br />
                            </form>
                            <a href="/login" className="text-center">I already have a membership</a>
                        </div>
                        {/* /.form-box */}
                    </div>{/* /.card */}
                </div>
            </body>

        </>
    )
}

export default Register;