import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCAtABclt7iKgY01FGL0pjhCFzakdzjgA8",
  authDomain: "resto-bar-c5df8.firebaseapp.com",
  projectId: "resto-bar-c5df8",
  storageBucket: "resto-bar-c5df8.appspot.com",
  messagingSenderId: "354884026881",
  appId: "1:354884026881:web:3bd3fa74ad61d0652fb600"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth  = getAuth();
export const db  = getFirestore(app);
export default app;